const detailProg1 = [
  {
    description: `F. Couperin (1668-1733) : Rondeau extrait du XVIIIème ordre pour
    clavecin 4’07’’`,
  },
  {
    description: `J.S.Bach (1685-1750) : Prélude et Fugue BWV 862 2’04’’ – 3’19’’`,
  },
  { description: `Wolfgang Amadeus Mozart (1756-1791): berceuse Kv 350 2’` },
  {
    description: `L.V. Beethoven (1770-1827) : Pour Elise , bagatelle en la m 3’27’’`,
  },
  { description: `Frédéric Chopin (1810-1849) : Nocturne 20 a opus 16 4’33’’` },
  {
    description: `F. Liszt (1811-1886): Marche solennelle de Parsifal /R. Wagner 11’21’’`,
  },
  {
    description: `Johannes Brahms (1833-1897) : Intermezzo opus 118 n°1 et 2 9’05’’`,
  },
  {
    description: `Erik Satie (1866-1925) : trois gymnopédies 3’05’’ – 2’36’’ – 2’28’’`,
  },
  {
    description: `Déodat de Séverac (1873-1921): Les muletiers devant le christ de Llivia 9’30’’`,
  },
  {
    description: `Francis Poulenc (1899-1963): Improvisation XV hommage à E.Piaf 3’23’’`,
  },
  {
    description: ` Serge Rachmaninoff (1873-1943): préludes op.23 n°12 , op. 3 n°2 4’21’’ – 4’34’’`,
  },
  { description: ` Olivier Messiaen (1908-1992) : la colombe 2’40’’` },
]

export default detailProg1
