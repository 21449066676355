import React from "react"
import Layout from "../components/Layout/layout"
import Header from "../components/Layout/header"
import Prog from "../components/programme"
import "../style/style.css"

const Programme = () => {
  return (
    <Layout>
      <Header header="page3" />
      <Prog />
    </Layout>
  )
}

export default Programme
