const detailProg2 = [
  {
    description: `J.S.Bach : Jésus, que ma joie demeure extrait de la cantate 147 4'40"`,
  },
  {
    description: `F.Couperin : Rondeau « soeur monique » extrait du XVlllème Ordre pour clavecin 3'10"`,
  },
  { description: `Wolfgang Amadeus Mozart : Berceuse Kv 350 2'` },
  {
    description: `L.V.Beethoven : Pour Elise , bagatelle en la m	3'27"`,
  },
  { description: `Franz Schubert : Sérénade D 957 4'50"` },
  {
    description: `Ferenc Liszt : Angelus ! 3ème année de Pèlerinage 7'29"`,
  },
  {
    description: `Frédéric Chopin : Nocturne 20 a opus 16 4'33"`,
  },
  {
    description: `Johannes Brahms : Intermezzo opus 118 n°1 & n°2 9'05"`,
  },
  {
    description: `Erik Satie : trois gymnopédies 3'05" — 2'36" — 2'28"`,
  },
  {
    description: `Erik Satie : quatre ogives 7'06"`,
  },
  {
    description: `Manuel de Falla : Le Tombeau de Claude Debussy 2'55"`,
  },
  { description: `Olivier Messiaen : la colombe	2'40"` },
  { description: `Stéphane Blet : Palestine I 2'20"` },
  { description: `Henri Dutilleux : Prélude en berceuse 3'30"` },
]

export default detailProg2
