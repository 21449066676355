import React, { useState } from "react"
import Styles from "./programme.module.css"
import detailProg1 from "../data/programme1"
import detailProg2 from "../data/programme2"
import image from "../asset/programme.jpg"

const Prog = () => {
  const [selected, setSelected] = useState("concert")

  const handleSelected = () => {
    switch (selected) {
      case "concert":
        return (
          <div className={Styles.concert}>
            <p>
              Concert à Colmar Eglise St Matthieu Samedi 29 octobre 18h en
              hommage à A.Schweitzer 70 ans PRIX NOBEL de la PAIX 1952
              <br /> le Samedi 29 octobre 2022
            </p>
            <p>
              ALBERT SCHWEITZER PRIX NOBEL de la PAIX 1952 <br />
              <br />A l'occasion de ce soixante-dizième Anniversaire, avec la
              parrainage de la Maison Albert Schweitzer l'ASMEO vous proposera
              dans le CHOEUR de Saint Matthieu <br />
              <br />
              Le Vendredi 21 Octobre à 20 heures <br /> un film documentaire:
              ALBERT SCHWEITZER et LAMBARENE avec la voix d'Albert Schweitzer,
              (parlant allemand et sous titré français). <br />
              <br />
              Le mercredi 26 Octobre à 20 heures
              <br />
              une conférence par Gabriel Braeuner: Albert Schweitzer <br />
              <br />
              Le samedi 29 Octobre à 18h30 <br />
              un concert de piano par Nathalie Guillermic Hommage à Albert
              Schweitzer (J.S.Bach,O. Messian,..et deux compositions d'Albert
              Schweitzer-inédites à ce jour)
            </p>
          </div>
        )

      case "programme1":
        return (
          <div className={Styles.prog1}>
            <p>
              Récital de Piano au Choeur de l'église Saint Matthieu à Colmar,
              <br /> le Mardi 31 Octobre 2017
            </p>
            {detailProg1.map(element => {
              return (
                <li key={detailProg1.indexOf(element)}>
                  {element.description}
                </li>
              )
            })}
          </div>
        )
        break
      case "programme2":
        return (
          <div className={Styles.prog2}>
            <p>
              Récital de Piano de 75 mn à l'église Saint Julien le Pauvre -
              Paris, <br />
              Le mardi 31 Janvier 2017
            </p>
            {detailProg2.map(element => {
              return <li key={element.index}>{element.description}</li>
            })}
          </div>
        )
        break
      default:
        return null
    }
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.card}>
        <div className={Styles.title}>SUGGESTION DE PROGRAMME</div>
        <div className={Styles.menu}>
          <div
            onClick={event => {
              setSelected("concert")
            }}
          >
            {selected === "concert" ? (
              <div className={Styles.list} style={{ color: "#5c8eda" }}>
                CONCERT
              </div>
            ) : (
              <div className={Styles.list} style={{ color: "#6f7479" }}>
                CONCERT
              </div>
            )}
          </div>

          <div
            onClick={event => {
              setSelected("programme1")
            }}
          >
            {selected === "programme1" ? (
              <div className={Styles.list} style={{ color: "#5c8eda" }}>
                PROGRAMME 1
              </div>
            ) : (
              <div className={Styles.list} style={{ color: "#6f7479" }}>
                PROGRAMME 1
              </div>
            )}
          </div>
          <div
            onClick={event => {
              setSelected("programme2")
            }}
          >
            {selected === "programme2" ? (
              <div className={Styles.list} style={{ color: "#5c8eda" }}>
                PROGRAMME 2
              </div>
            ) : (
              <div className={Styles.list} style={{ color: "#6f7479" }}>
                PROGRAMME 2
              </div>
            )}
          </div>
        </div>
        <div className={Styles.programme}>{handleSelected()}</div>
        <div className={Styles.footerProgramme}>
          <img src={image} width={200} height={200} className={Styles.image} />
          <div className={Styles.footer}>
            Réservation et renseignements
            <div className={Styles.footerSubTitle}>
              <a style={{ color: "white" }} href="tel:06-89-61-72-52">
                &nbsp;&nbsp;06 89 61 72 52
              </a>
            </div>
            <div
              className={Styles.footerSubTitle}
              style={{ marginBottom: "10px" }}
            >
              <a
                style={{ color: "white" }}
                href="mailto:lesharmoniques27@orange.fr"
              >
                &nbsp;&nbsp;lesharmoniques27@orange.fr
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prog
